import { render, staticRenderFns } from "./HsCom6.vue?vue&type=template&id=dbef9d94&scoped=true&"
import script from "./HsCom6.vue?vue&type=script&lang=js&"
export * from "./HsCom6.vue?vue&type=script&lang=js&"
import style0 from "./HsCom6.vue?vue&type=style&index=0&id=dbef9d94&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbef9d94",
  null
  
)

export default component.exports