<template>
    <div class="hsContent">
        <!-- 内容一 -->
        <hsCom1></hsCom1>
        <!-- 内容二 -->
        <hsCom2></hsCom2>
        <!-- 内容三 -->
        <hsCom3></hsCom3>
        <!-- 内容四 -->
        <hsCom4></hsCom4>
        <!-- 内容五 -->
        <hsCom5></hsCom5>
        <!-- 内容六 -->
        <hsCom6></hsCom6>
        <!-- 内容七 -->
        <hsCom7></hsCom7>
        <!-- 内容八 -->
        <hsCom8></hsCom8>
    </div>
</template>

<script>
import hsCom1 from "./HsCom1.vue";
import hsCom2 from "./HsCom2.vue";
import hsCom3 from "./HsCom3.vue";
import hsCom4 from "./HsCom4.vue";
import hsCom5 from "./HsCom5.vue";
import hsCom6 from "./HsCom6.vue";
import hsCom7 from "./HsCom7.vue";
import hsCom8 from "./HsCom8.vue";

export default {
    name: "HsContent",
    components: {
        hsCom1,
        hsCom2,
        hsCom3,
        hsCom4,
        hsCom5,
        hsCom6,
        hsCom7,
        hsCom8,
    },
};
</script>

<style lang="less" scoped></style>
