<template>
    <div class="hsCom1" v-if="getAdvData(11).children">
        <a :href="getAdvData(11).children[0].url" target="_blank" rel="noopener noreferrer">
            <img :src="getAdvData(11).children[0].image" />
            <div class="hc1Box">
                <!-- 标题 -->
                <div class="hc1Title wow animate__animated animate__slideInUp">
                    <p class="hc1Big">威有科技·企业官网</p>
                    <p class="hc1Small">由表及里 完全与众不同</p>
                </div>
                <!-- 列表 -->
                <div class="hc1List wow animate__animated animate__slideInUp">
                    <ul>
                        <li>专属风格</li>
                        <li>标准网站功能</li>
                        <li>网站安全部署</li>
                        <li>一站式后台管理</li>
                    </ul>
                </div>
                <!-- 图片 -->
                <div class="hc1Imgs wow animate__animated animate__slideInUp">
                    <img :src="getAdvData(11).children[0].children.find(e => e.id == 206).image" />
                    <img :src="getAdvData(11).children[0].children.find(e => e.id == 207).image" />
                    <img :src="getAdvData(11).children[0].children.find(e => e.id == 208).image" />
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "HsCom1",
    inject: ["getAdvData"],
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.hsCom1 {
    // min-width: 1423px;
    width: 100%;
    // height: 500px;
    position: relative;
    & > a {
        width: 100%;
        height: 100%;
        display: block;
        & > img {
            width: 100%;
            // max-width: 100%;
            min-height: 520px;
        }
    }
    .hc1Box {
        width: 1280px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // 标题
        .hc1Title {
            width: 40%;
            height: 100px;
            margin: 0 auto;
            text-align: center;
            color: white;
            font-weight: 900;
            line-height: 50px;
            .hc1Big {
                width: 100%;
                height: 50px;
                font-size: 40px;
            }
            .hc1Small {
                width: 100%;
                height: 50px;
                font-size: 30px;
            }
        }
        // 列表
        .hc1List {
            width: 50%;
            height: 50px;
            margin: 0 auto;
            margin-top: 20px;
            text-align: center;
            line-height: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                li {
                    width: 20%;
                    height: 50px;
                    font-size: 17px;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px 1px #eee;
                    color: white;
                    cursor: pointer;
                }
                li:hover {
                    background-color: rgb(68, 163, 180);
                }
            }
        }
        // 图片
        .hc1Imgs {
            width: 60%;
            height: 300px;
            margin: 0 auto;
            margin-top: 5px;
            img {
                padding-top: 20px;
                width: 33.33%;
            }
        }
    }
}
</style>
