<template>
    <div class="hsCom4">
        <!-- 文字 -->
        <div class="hc4Title">
            <!-- 顶部文字 -->
            <div class="hc4Top wow animate__animated animate__slideInUp">
                <p class="hc4Big">标准功能·自由定制</p>
                <p class="hc4Small">每一个官方网站都是一件完美的产品</p>
            </div>
            <!-- 底部文字 -->
            <div class="hc4Bottom wow animate__animated animate__slideInUp">
                <!-- 左文 -->
                <div class="hc4Left">
                    <p class="hc4Top">标准功能</p>
                    <p class="hc4Text">
                        成熟！完善！威有科技7年行业经验，打磨出规范建站技术，并取得
                        国家认可，通过多项专利认证。威有科技以坚实技术基础，确保每一
                        网站都具备完善功能；以工匠态度，致力让每一网站都成为卓越精品。
                    </p>
                </div>
                <!-- 右文 -->
                <div class="hc4Right">
                    <p class="hc4Top">自由定制</p>
                    <p class="hc4Text">
                        在资深网站策划协助下，企业还可以自由打造官网专属功能。<br />
                        只要提供您的想法，我们就能帮你把想法变为现实！
                    </p>
                </div>
            </div>
        </div>
        <!-- 图片 -->
        <img src="http://iv.okvu.cn/vugw/img/hc1131.jpg" alt="" class="hc4Img1" />
        <img src="http://iv.okvu.cn/vugw/img/hc1132.jpg" alt="" class="hc4Img2" />
    </div>
</template>

<script>
export default {
    name: "HsCom4",
};
</script>

<style lang="less" scoped>
.hsCom4 {
    width: 100%;
    height: 900px;
    margin: 0 auto;
    background: url("http://iv.okvu.cn/vugw/img/a4c8fa55cd38713f74249e45b3bd82ab_gx.jpg") center no-repeat;
    color: white;
    position: relative;
    overflow: hidden;
    // 文字
    .hc4Title {
        width: 1280px;
        height: 250px;
        margin: 0 auto;
        margin-top: 150px;
        // 顶部文字
        .hc4Top {
            width: 50%;
            height: 70px;
            margin: 0 auto;
            text-align: center;
            .hc4Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
            }
            .hc4Small {
                width: 100%;
                height: 20px;
                line-height: 20px;
            }
        }
        // 底部文字
        .hc4Bottom {
            width: 100%;
            height: 170px;
            margin-top: 10px;
            .hc4Left,
            .hc4Right {
                width: 520px;
                height: 100%;
                float: left;
                margin-top: 20px;
                .hc4Top {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    text-align: left;
                }
                .hc4Text {
                    width: 100%;
                    height: 140px;
                    line-height: 30px;
                }
            }
            .hc4Right {
                float: right;
            }
        }
    }
    // 图片
    .hc4Img1 {
        position: absolute;
        bottom: 40px;
        left: 118px;
    }
    // 图片
    .hc4Img2 {
        position: absolute;
        bottom: 42px;
        right: 121px;
    }
}
</style>
